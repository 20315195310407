<template>
  <v-app>
    <v-main>
      <HelloWorld/>
      <Footer/>
    </v-main>
  </v-app>
</template>

<script>
import HelloWorld from './components/FrontPage';
import "./assets/style/style.css";
import"./assets/style/fonts.css";
import Footer from './components/FooterPage'

export default {
  name: 'App',

  components: {
    HelloWorld,
    Footer,
  },

  data: () => ({
    //
  }),
};
</script>
