<template>
  <div>
    <v-layout wrap style="background-color: #0c5f33" py-4>
      <v-flex xs12 sm12 md12 lg12>
        <v-flex xs12 text-center>
          <span class="white--text" style="font-size: 25px">Bottom Tab</span>
        </v-flex>
        <v-flex xs12 text-center>
          <span class="white--text" style="font-size: 25px">
            Tabs/Thumbnails leading Tab
          </span>
        </v-flex>
        <v-layout wrap>
          <v-flex xs12 sm12 md12 lg12>
            <v-layout wrap>
              <v-flex text-center v-for="(item, i) in globe" :key="i" ma-6>
                <v-avatar size="150px">
                  <v-img :src="item.img" class="pr-1">
                    <v-layout wrap justify-center>
                      <v-flex xs8 pt-3 align-self-center>
                        <span class="bold" style="font-size: 17px">{{
                          item.text
                        }}</span>
                      </v-flex>
                    </v-layout>
                  </v-img>
                </v-avatar>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  data() {
    return {
      globe: [
        {
          img: require("../assets/Images/5.1.jpg"),
          text: "PROTECTED AREA RECOVERY",
        },
        {
          img: require("../assets/Images/5.1.jpg"),
          text: "WILD RESCUE",
        },
        {
          img: require("../assets/Images/5.1.jpg"),
          text: "NATURAL HERITAGE CAMPAIGNS",
        },
        {
          img: require("../assets/Images/5.1.jpg"),
          text: "RIGHT OF PASSAGE",
        },
        {
          img: require("../assets/Images/5.1.jpg"),
          text: "CONFLICT MITIGATION",
        },
        {
          img: require("../assets/Images/5.1.jpg"),
          text: "WILD LANDS",
        },
        {
          img: require("../assets/Images/5.1.jpg"),
          text: "ENFORCEMENT & LAW",
        },
        {
          img: require("../assets/Images/5.1.jpg"),
          text: "SPECIES RECOVERY",
        },
        {
          img: require("../assets/Images/5.1.jpg"),
          text: "WILD  AID",
        },
      ],
    };
  },
};
</script>